import React from 'react';

import Icon from 'components/atoms/Icon';
import mapModifiers from 'utils/functions';

export type SizeImageType = 'cover' | 'contain' | 'inherit' | 'initial';

interface ImageProps {
  imgSrc: string;
  srcTablet?: string;
  srcMobile?: string;
  alt: string;
  ratio: Ratio;
  size?: SizeImageType;
  loading?: 'lazy' | 'eager';
  handleClose?: () => void;
  handleClick?: () => void;
}

const Image: React.FC<ImageProps> = ({
  imgSrc,
  srcTablet,
  srcMobile,
  alt,
  ratio,
  size,
  loading,
  handleClose,
  handleClick,
}) => (
  <picture className={mapModifiers('a-image', ratio, size)}>
    {
      handleClose && (
        <div onClick={handleClose} className="a-image_iconClose">
          <Icon size="20" iconName="close" />
        </div>
      )

    }
    <source media="(max-width:992px)" srcSet={srcTablet || imgSrc} />
    <source media="(max-width:576px)" srcSet={srcMobile || imgSrc} />
    <img onClick={handleClick} src={imgSrc} alt={alt} loading={loading} />
  </picture>
);

export const ImageBackground: React.FC<ImageProps> = ({
  imgSrc,
  alt,
  ratio,
  size,
  srcTablet,
  srcMobile,
  handleClose,
  handleClick,
  loading,
}) => (
  <picture className={mapModifiers('a-backgroundImage', ratio, size)} style={{ backgroundImage: `url(${imgSrc})` }}>
    {
      handleClose && (
        <div onClick={handleClose} className="a-backgroundImage_iconClose">
          <Icon size="12" iconName="close" />
        </div>
      )
    }
    <source media="(max-width:992px)" srcSet={srcTablet || imgSrc} />
    <source media="(max-width:576px)" srcSet={srcMobile || imgSrc} />
    <img onClick={handleClick} src={imgSrc} alt={alt} loading={loading} />
  </picture>
);
export default Image;
