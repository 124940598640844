function mapModifiers(
  baseClassName: string,
  ...modifiers: (string | string[] | false | undefined)[]
): string {
  return modifiers
    .reduce<string[]>(
      (acc, m) => (!m ? acc : [...acc, ...(typeof m === 'string' ? [m] : m)]),
      [],
    )
    .map((m) => `-${m}`)
    .reduce<string>(
      (classNames, suffix) => `${classNames} ${baseClassName}${suffix}`,
      baseClassName,
    );
}

export default mapModifiers;

/*!
 * Scroll down to next block element
 */
export function scrollDownNextSection(ref: React.RefObject<HTMLDivElement>) {
  if (ref && ref.current) {
    window.scrollTo(
      { behavior: 'smooth', top: ref.current.offsetTop - 68 },
    ); // Minus header height
  }
}

/*!
 * getMousePosition(event) - cross browser normalizing of:
 * clientX, clientY, screenX, screenY, offsetX, offsetY, pageX, pageY
 * HTMLElement
 */
export function getMousePosition(
  evt:
    | React.MouseEvent<SVGPathElement, MouseEvent>
    | React.MouseEvent<SVGRectElement, MouseEvent>,
  item: HTMLDivElement,
) {
  let { pageX } = evt;
  let { pageY } = evt;
  if (pageX === undefined) {
    pageX = evt.clientX
      + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    pageY = evt.clientY
      + document.body.scrollTop
      + document.documentElement.scrollTop;
  }

  const rect = item.getBoundingClientRect();
  const offsetX = evt.clientX - rect.left;
  const offsetY = evt.clientY - rect.top;

  return {
    client: { x: evt.clientX, y: evt.clientY }, // relative to the viewport
    screen: { x: evt.screenX, y: evt.screenY }, // relative to the physical screen
    offset: { x: offsetX, y: offsetY }, // relative to the event target
    page: { x: pageX, y: pageY }, // relative to the html document
  };
}

export function getDimensions(ele: HTMLDivElement) {
  const { height } = ele.getBoundingClientRect();
  const { offsetTop } = ele;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
}

export function scrollStop(callback: (value: any) => void, time = 2000) {
  // Make sure a valid callback was provided
  if (!callback || typeof callback !== 'function') return;

  // Setup scrolling variable
  let isScrolling: any;

  // Listen for scroll events
  window.addEventListener(
    'scroll',
    () => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(callback, time);
    },
    false,
  );
}

export const formatDateDDMMYYY = (date?: Date) => {
  if (!date) return '';
  const dateFormat = new Date(date);
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${day}/${month}/${dateFormat.getFullYear()}`;
};

export const formatDateYYYYMMDD = (date?: Date) => {
  if (!date) return '';
  const dateFormat = new Date(date);
  let day: string | number = dateFormat.getDate();
  let month: string | number = dateFormat.getMonth() + 1;
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${dateFormat.getFullYear()}-${month}-${day}`;
};

const validDimensionByMimetype = (mimeType: string) => {
  let width = 0;
  let height = 0;
  if (mimeType === 'image/png') {
    width = 1280;
    height = 960;
  } else {
    width = 1920;
    height = 1440;
  }
  return {
    width,
    height,
  };
};

export const compressImage = (
  name: string,
  file: File,
  mineType: string,
  quality: number = 1,
): Promise<{
  returnedFile: File,
  dataUrl: string,
}> => {
  let resolver = (data: any) => data;
  const url = URL.createObjectURL(file);
  const imager = document.createElement('img');
  imager.src = url;
  const canvas = document.createElement('canvas');
  const validDimension = validDimensionByMimetype(mineType);
  imager.onload = () => {
    const maxWidth = validDimension.width;
    const maxHeight = validDimension.height;

    const imgwidth = imager.width;
    const imgheight = imager.height;
    let ratio = Math.min(maxWidth / imgwidth, maxHeight / imgheight);
    if (ratio > 1) {
      ratio = 1;
    }

    const newWidth = imgwidth * ratio;
    const newHeight = imgheight * ratio;
    if ((newWidth < 480 || newHeight < 480)) {
      if (newWidth < newHeight) {
        canvas.width = 960;
        canvas.height = (newHeight * 960) / newWidth;
      } else {
        canvas.height = 960;
        canvas.width = (newWidth * 960) / newHeight;
      }
    } else {
      canvas.width = newWidth;
      canvas.height = newHeight;
    }
    canvas.getContext('2d')?.drawImage(
      imager,
      0,
      0,
      canvas.width,
      canvas.height,
    );
    canvas.toBlob(
      (b) => {
        if (b) {
          const result = {
            returnedFile: new File([b], name || 'filename', { type: mineType }),
            dataUrl: URL.createObjectURL(b),
          };
          resolver(result);
        }
      },
      mineType,
      quality,
    );
  };
  return new Promise((resolve) => {
    resolver = resolve;
  });
};
