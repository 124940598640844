/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { FineUploaderBasic } from 'fine-uploader/lib/core';
import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Images, { ImagesActionProps } from './Container/Images';
import Job, { JobActionProps } from './Container/Job';
import PersonalInformation, { PersonalInformationActionRef } from './Container/PersonalInformation';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import Datepicker from 'components/molecules/Datepicker';
import NotifyModal from 'components/templates/NotifyModal';
import useDebounce from 'hooks/useDebounce';
import useInitializeRender from 'hooks/useInitializeRender';
import { clearLocalStorage } from 'services/common/storage';
import {
  getSourceParttimesService,
  mergeImageService,
  updateSourceParttimesService,
  getAgencyService,
  checkIdentificationService,
  getProjectService,
} from 'services/sourceParttimes';
import {
  GetSourceParttimesParams,
  MergeImageParams,
  SourcePartTimes,
  UpdateSourceParttimesParams,
  SourcePartTimesEditData
} from 'services/sourceParttimes/types';
import { IMAGE_SIZE_1MB, URL_CONST } from 'utils/constants';
import { compressImage, formatDateDDMMYYY, formatDateYYYYMMDD } from 'utils/functions';
import { checkIdentifySchema } from 'utils/schemas';

type EditFormType = {
  load_field: string;
  phone: string;
  birthday: Date | null;
};

const Update: React.FC = () => {
  useInitializeRender();

  const { slug: agencyCode } = useParams<{ slug: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    message: '',
  });
  const [imgUuidList, setImgUuidList] = useState<{
    uuid: string,
    name: string
  }[]>([]);
  const [submittingData, setSubmittingData] = useState<SourcePartTimes>();
  const [getData, setGetData] = useState<SourcePartTimesEditData>();
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotFoundAgencyCode, setIsNotFoundAgencyCode] = useState(false);
  const [handleIdentity, setHandleIdentity] = useState('');
  const [code, setCode] = useState('');
  const [isErrorCode, setIsErrorCode] = useState(false);
  const [codeMessage, setCodeMessage] = useState<string | undefined>('');

  const personalInformationRef = useRef<PersonalInformationActionRef>(null);
  const jobRef = useRef<JobActionProps>(null);
  const imagesRef = useRef<ImagesActionProps>(null);

  const newUploadImgRef = useRef<number>(0);

  const editMethod = useForm<EditFormType>({
    mode: 'onChange',
    defaultValues: {
      load_field: '',
      phone: '',
      birthday: null,
    },
    resolver: yupResolver(checkIdentifySchema),
  });

  //* React-query

  const {
    mutate: mutateCheckCode,
    isLoading: loadingCode
  } = useMutation(checkIdentificationService, {
    // onSuccess() {
    //   setCodeMessage('Số CCCD / CMND có thể sử dụng.');
    //   personalInformationRef.current?.clearErrors('cmnd');
    //   setIsErrorCode(false);
    // },
    // onError: () => {
    //   setIsErrorCode(true);
    //   setCodeMessage(undefined);
    //   personalInformationRef.current?.handleSetError('cmnd', 'Số CCCD / CMND đã được đăng kí!');
    // }
  });

  const { isLoading: agenciesLoading } = useQuery(
    ['project', 'agencyCode', agencyCode],
    () => getAgencyService({
      code: agencyCode || '',
    }),
    {
      enabled: !!agencyCode,
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFoundAgencyCode(true);
          }
        } else {
          setErrorModal({
            open: true,
            message: 'Đã có lỗi xảy ra! Vui lòng thử lại!',
          });
        }
      }
    }
  );

  const { data: projectRes } = useQuery(
    ['getProjectListUpdate', agencyCode],
    () => getProjectService({
      agency: agencyCode || '',
    }),
  );

  const projectList = useMemo(() => {
    if (projectRes && projectRes.length > 0) {
      const data = projectRes.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.name,
      }));
      return [
        ...data,
        {
          id: -1,
          value: 'other',
          label: 'Khác',
        }
      ];
    }
    return [
      {
        id: -1,
        value: 'other',
        label: 'Khác',
      }
    ];
  }, [projectRes]);

  const prjDefaultData = useMemo(() => {
    const tempData = projectList.filter(
      (item) => getData?.projects.includes(Number(item.id)),
    );
    if (getData?.name_program) {
      return [
        ...tempData,
        {
          id: -1,
          value: 'other',
          label: 'Khác',
        },
      ];
    }
    return tempData;
  }, [getData, projectList]);

  const {
    mutate: getDataMutate,
    isLoading: getDataLoading,
    data: dataRes,
    reset: resetGetData,
  } = useMutation(
    ['get-source-parttimes'],
    async (params: GetSourceParttimesParams) => getSourceParttimesService(params),
    {
      onSuccess: (res) => {
        setIsNotFound(false);
        setGetData(res);
      },
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        } else {
          setErrorModal({
            open: true,
            message: 'Đã có lỗi xảy ra! Vui lòng thử lại!',
          });
        }
      },
    }
  );

  const { mutate: mergeMutate } = useMutation(
    ['merge-image-source-parttimes'],
    async (params: MergeImageParams) => mergeImageService(params),
    {
      onSettled: () => {
        setImgUuidList([]);
      },
      onSuccess: () => {
        setIsLoading(false);
        setIsOpen(true);
        clearLocalStorage();
        jobRef.current?.clearOthersForm();
        personalInformationRef.current?.clearOthersForm();
        imagesRef.current?.clearOthersForm();
        setSubmittingData(undefined);
        editMethod.reset();
        resetGetData();
        newUploadImgRef.current = 0;
      },
      onError: () => {
        setIsLoading(false);
        setErrorModal({
          open: true,
          message: 'Gửi ảnh thất bại! Vui lòng thử lại!',
        });
      },
    }
  );

  const { mutate: postMutate } = useMutation(
    ['post-source-parttimes'],
    async (params: UpdateSourceParttimesParams) => updateSourceParttimesService(params),
    {
      onSuccess: () => {
        if (getData && newUploadImgRef.current > 0) {
          const submitData = imgUuidList.reduce((prev: any, curr) => {
            if (curr.name.split('-')[0] === 'faceIDleft') {
              return {
                ...prev,
                left_corner: {
                  file_uuid: curr.uuid
                }
              };
            }
            if (curr.name.split('-')[0] === 'faceIDfront') {
              return {
                ...prev,
                direct: {
                  file_uuid: curr.uuid
                }
              };
            }
            if (curr.name.split('-')[0] === 'faceIDright') {
              return {
                ...prev,
                right_corner: {
                  file_uuid: curr.uuid
                }
              };
            }
            if (curr.name.split('-')[0] === 'thumbnail') {
              return {
                ...prev,
                profile_picture: [...(prev.profile_picture || []), curr.uuid]
              };
            }
            return prev;
          }, {});

          if (Object.keys(submitData).length) {
            mergeMutate({
              source_id: Number(getData.id),
              data: submitData
            });
          }
        } else {
          setIsLoading(false);
          jobRef.current?.clearOthersForm();
          personalInformationRef.current?.clearOthersForm();
          imagesRef.current?.clearOthersForm();
          editMethod.reset();
          setIsOpen(true);
          resetGetData();
          newUploadImgRef.current = 0;
        }
      },
      onError: (errors, res) => {
        setImgUuidList([]);
        setIsLoading(false);
        if (Array.isArray(errors) && errors.length > 0) {
          if (errors[0].field === 'cmnd_cccd') {
            setErrorModal({
              open: true,
              message: `Số CMND/CCCD ${res.cmnd_cccd} đã được đăng ký!`,
            });
            // personalInformationRef?.current?.handleSetError('cmnd', 'Số CMND/CCCD đã được đăng ký!');
          } else {
            setErrorModal({
              open: true,
              message: 'Gửi thông tin thất bại! Vui lòng thử lại!'
            });
          }
        }
      },
    }
  );

  //* Fine-uploader
  const uploader = new FineUploaderBasic({
    autoUpload: false,
    request: {
      endpoint: URL_CONST.MEDIA_FILE_UPLOAD_CHUNK,
      uuidName: 'file_uuid',
      inputName: 'file',
    },
    chunking: {
      enabled: true,
      mandatory: true,
      partSize: IMAGE_SIZE_1MB, // 1MB,
      paramNames: {
        partIndex: 'file_index',
      }
    },
    callbacks: {
      onUpload(id: number) {
        const uuid = uploader.getUuid(id) as string;
        const file = uploader.getFile(id);
        setImgUuidList((prev) => [...prev, { uuid, name: file.name }]);
      },
      onComplete(id: number, name: string, res: {
        success: boolean;
        data?: Object;
      }) {
        const uuid = uploader.getUuid(id) as string;
        if (!res.success) {
          // const errors = JSON.parse(xhr.response).errors as ErrorResponse[];
          setErrorModal({
            open: true,
            message: 'Upload ảnh thất bại! Vui lòng thử lại!'
          });
          setImgUuidList((prev) => ([...prev.filter((ele) => ele.uuid !== uuid)]));
        }
      },
    }
  });

  //* Functions
  const handleSubmit = async () => {
    const valueJob = await jobRef.current?.handleForm();
    const valueImages = await imagesRef.current?.handleForm();
    const valuePersonalInformation = await personalInformationRef.current?.handleForm();
    const data = { ...getData };

    if (valueJob
      && valueImages
      && valuePersonalInformation) {
      setIsLoading(true);

      const compressedThumbnail = valueImages?.listThumbnail ? await Promise.all(
        valueImages.listThumbnail.map(async (ele, index) => {
          const {
            returnedFile,
          } = await compressImage(`thumbnail-${index.toString()}`, ele, 'image/jpeg', 0.7);
          return returnedFile;
        })
      ) : undefined;

      const submitData = {
        name: valuePersonalInformation?.name || data.name || '',
        gender: valuePersonalInformation?.gender || Number(data.gender) || 0,
        birthday: formatDateDDMMYYY(valuePersonalInformation?.birthday
          || dayjs(data.birthday).toDate()),
        cellphone: valuePersonalInformation?.phone || data.cellphone || '',
        cmnd_cccd: handleIdentity || '',
        lang_sp: valueJob?.language || data.lang_sp || [],
        name_program: valueJob?.otherName || data.name_program || '',
        project_ids: valueJob?.prjName
          ?.filter((el) => el.value !== 'other')
          .map((item) => String(item.id)) || [],
        picture: {
          direct: valueImages?.faceIDFront !== data.picture?.direct
            ? valueImages?.faceIDFront : data.picture?.direct,
          left_corner: valueImages?.faceIDLeft !== data.picture?.left_corner
            ? valueImages?.faceIDLeft : data.picture?.left_corner,
          right_corner: valueImages?.faceIDRight !== data.picture?.right_corner
            ? valueImages?.faceIDRight : data.picture?.right_corner,
          profile_picture: compressedThumbnail,
        },
        agency_code: agencyCode || '',
        source: 2,
      };
      setSubmittingData(submitData);

      if (compressedThumbnail && compressedThumbnail.length > 0) {
        uploader.addFiles([...compressedThumbnail]);
        newUploadImgRef.current += compressedThumbnail.length;
      }
      if (newUploadImgRef.current > 0) {
        uploader.uploadStoredFiles();
      } else {
        postMutate({ id: handleIdentity, ...submitData });
      }
    } else {
      setErrorModal({
        open: true,
        message: 'Vui lòng kiểm tra và nhập đầy đủ thông tin cần thiết!'
      });
    }
  };

  const handleLoadData = (data: EditFormType) => {
    getDataMutate({
      id: data.load_field,
      source: 2,
      agency_code: agencyCode || '',
      birthday: formatDateYYYYMMDD(dayjs(data.birthday).toDate()) || null,
      phone: data.phone
    });
    setHandleIdentity(data.load_field);
  };

  useDebounce(() => {
    if ((code !== handleIdentity) && (code.length >= 9 && code.length <= 12)) {
      mutateCheckCode({ cmnd_cccd: code });
    } else if (code === handleIdentity) {
      // personalInformationRef.current?.clearErrors('cmnd');
      setIsErrorCode(false);
      setCodeMessage('Số CCCD / CMND có thể sử dụng.');
    }
  }, 1000, [code]);

  //* Effects
  useEffect(() => {
    if (handleIdentity && !!imgUuidList.length && submittingData
      && imgUuidList.length === newUploadImgRef.current) {
      const data = { ...submittingData };
      postMutate({ id: handleIdentity, ...data });
    }
  }, [imgUuidList, submittingData, postMutate, handleIdentity]);

  useEffect(() => {
    if (getData
      && jobRef.current
      && personalInformationRef.current
      && imagesRef.current) {
      const data = { ...getData };
      jobRef.current?.handleReset({
        language: [],
        prjName: prjDefaultData,
        otherName: data.name_program,
        confirm: false,
      });
      personalInformationRef.current?.handleReset({
        name: data.name || '',
        gender: data.gender !== null ? Number(data.gender) : null,
        phone: data.cellphone || '',
        birthday: data.birthday ? dayjs(data.birthday).toDate() : null,
      });
      // imagesRef.current.handleReset({
      //   faceIdLeftSrc: data.picture?.left_corner,
      //   faceIdFrontSrc: data.picture?.direct,
      //   faceIdRightSrc: data.picture?.right_corner,
      //   listImages: data.picture?.profile_picture || [],
      // });
    }
  }, [
    getData,
    prjDefaultData,
  ]);

  if (!agencyCode) {
    return (
      <div className="invalid">
        <Heading modifiers={['32x42', '700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (agenciesLoading) {
    return (
      <Loading isShow />
    );
  }

  if (isNotFoundAgencyCode) {
    return (
      <div className="invalid">
        <Heading modifiers={['32x42', '700', 'center']}>
          Không tìm thấy Agency mã
          {' '}
          {agencyCode || ''}
          , xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <div className="container">
      <FormProvider {...editMethod}>
        <div className="load-data-field">
          <Controller
            name="load_field"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                error={fieldState?.error?.message}
                required
                subLabel="Nhập CCCD/CMND"
                placeholder="Nhập CCCD/CMND"
              />
            )}
          />
          <div className="u-mt-12">
            <Controller
              name="phone"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  error={fieldState?.error?.message}
                  required
                  subLabel="Nhập số điện thoại"
                  placeholder="Nhập số điện thoại"
                />
              )}
            />
          </div>
          <div className="u-mt-12">
            <Controller
              name="birthday"
              render={({ field, fieldState }) => (
                <Datepicker
                  placeholderText="DD/MM/YYYY"
                  isVertical
                  onChange={field.onChange}
                  selected={field.value}
                  id="datepicker-birthday-load-field"
                  label="Ngày sinh"
                  required
                  dateFormat="dd/MM/yyyy"
                  error={fieldState?.error?.message}
                />
              )}
            />
          </div>
          <div className="load-data-field_button">
            <Button
              modifiers={['lg', 'primary']}
              onClick={editMethod.handleSubmit(handleLoadData)}
              loading={getDataLoading}
            >
              <Text modifiers={['white', '14x20', '600']}>
                Gửi
              </Text>
            </Button>
          </div>
        </div>
      </FormProvider>
      {
        isNotFound && (
          <Heading modifiers={['20x30', '700', 'center']}>
            Không tìm thấy thông tin
          </Heading>
        )
      }
      {
        !getDataLoading && dataRes && (
          <div className="form">
            {!dataRes.is_approve && (
              <div className="notify-text">
                <Text modifiers={['600', 'laSalleGreen']}>
                  Hồ sơ CTV của bạn chưa được duyệt, bạn có thể cập nhật thông tin dưới đây.
                </Text>
              </div>
            )}
            <PersonalInformation
              ref={personalInformationRef}
              handleChangeCode={setCode}
              loadingCode={loadingCode}
              codeMessage={codeMessage}
              isDisabled={dataRes.is_approve}
              isReadOnly={dataRes.is_approve}
            />
            <div className={`u-mt-24 ${dataRes.is_approve ? 'd-none' : ''}`}>
              <Images
                ref={imagesRef}
              />
            </div>
            <div className="u-mt-24">
              <Job
                ref={jobRef}
                projectList={projectList}
                isReadOnly={dataRes.is_approve}
              />
            </div>
            <div className="u-mt-24">
              <Button
                modifiers={['lg', 'primary']}
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isErrorCode || isLoading}
              >
                <Text modifiers={['white', '14x20', '600']}>
                  Cập nhật
                </Text>
              </Button>
            </div>
          </div>
        )
      }
      <NotifyModal
        isOpen={isOpen}
        title="Đã cập nhật thành công!"
        smallTitle
        submitText="Đóng"
        handleSubmit={() => setIsOpen(false)}
      />
      <NotifyModal
        isError
        isOpen={errorModal.open}
        title="Thất bại"
        desc={errorModal.message || 'Đã có lỗi xảy ra. Vui lòng thử lại!'}
        submitText="Đóng"
        handleSubmit={() => setErrorModal({
          open: false,
          message: '',
        })}
      />
    </div>
  );
};

export default Update;
